/**
 * Slider Component
 * This component is used so that users can select options for example
 * map selection.
 */

import React, { createRef } from 'react';
import styled from 'styled-components';
import Slider, { CustomArrowProps } from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';

import Flex from '../Flex';
import { SecondaryButton } from '../Button';

const PreviousArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <SecondaryButton onClick={onClick} className="left-button">
      <FontAwesomeIcon icon={faChevronLeft} size="2x" />
    </SecondaryButton>
  );
};

const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <SecondaryButton onClick={onClick} className="right-button">
      <FontAwesomeIcon icon={faChevronRight} size="2x" />
    </SecondaryButton>
  );
};

const StyledSlider = styled(Slider)`
  position: relative;

  ${SecondaryButton} {
    padding: 0;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 10;
    width: 75px;
    height: 75px;

    svg {
      color: ${props => props.theme.colors.ash};
    }

    &.left-button {
      left: -80px;
    }

    &.right-button {
      right: -80px;
    }
  }
`;

const ButtonWrap = styled(Flex)`
  background-color: #ffffff;
  padding: 24px 48px;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.grey};
  margin-top: 56px;
`;

interface IPropsContainer {
  noPadding?: boolean;
}

const SliderContainer = styled.div<IPropsContainer>`
  padding: ${props => (props.noPadding ? '0' : '32px 56px 0')};
`;

const StyledButton = styled(SecondaryButton)`
  color: #424242;
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  svg {
    color: ${props => props.theme.colors.gold};
  }
`;

interface IPickerProps {
  extraSettings: object;
  noPadding?: boolean;
  showNav?: boolean;
}

const Picker: React.FC<IPickerProps> = ({
  children,
  extraSettings,
  noPadding,
  showNav,
}) => {
  const slider: any = createRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    ...extraSettings,
  };

  return (
    <>
      <SliderContainer noPadding={noPadding}>
        <StyledSlider ref={slider} {...settings}>
          {children}
        </StyledSlider>
      </SliderContainer>

      {showNav && (
        <ButtonWrap>
          <div>
            <StyledButton onClick={() => slider.current.slickPrev()}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="1x"
                style={{ marginRight: 16 }}
              />
              Previous
            </StyledButton>
          </div>

          <div>
            <img
              src={require('./swipe-icon.png')}
              alt="Swipe through collection"
            />
          </div>

          <div>
            <StyledButton onClick={() => slider.current.slickNext()}>
              Next
              <FontAwesomeIcon
                icon={faArrowRight}
                size="1x"
                style={{ marginLeft: 16 }}
              />
            </StyledButton>
          </div>
        </ButtonWrap>
      )}
    </>
  );
};

export default Picker;
