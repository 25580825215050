import React, { useState } from "react";
import styled from "styled-components";
import { DefaultLayout } from "../layouts";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate, RouteComponentProps } from "@reach/router";
import { PrimaryButton } from "angel-ui";
import { filterProps, searchProps } from "../data/types";

const CollectionWrap = styled.div`
  *:focus {
    outline: none;
  }

  a {
    cursor: pointer;
  }
  
  h1 {
    margin: 0 0 27px 0;
    padding: 0;
    font-size: 58px;
    font-weight: 500;
    color: #424242;
  }

  h2 {
    margin: 40px 0;
    padding: 0;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: #424242;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 0;
    font-weight: 500;
    color: #424242;
  }

  p {
    font-size: 18px;
    margin-top: 0;
    font-weight: 300;
    color: #424242;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    text-decoration: none;
    color: inherit;
  }
`;
const Form = styled.form`
  background-color: white;
  border: 1px solid #d6d6d6;
  border-bottom-width: 7;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 58px;
  padding-bottom: 60px;
`;

const TextInput = styled.input`
  font-size: 22px;
  padding: 20px 15px 17px 15px;
  border: 1px solid #d9d9d9;
  background-color: #fff;

  ::placeholder {
    color: #000;
  }
`;

const Label = styled.span`
  font-size: 22px;
  padding: 20px 0 17px 0;
  text-align: center;
`;

const Select = styled.select`
  font-size: 22px;
  padding: 20px 15px 17px 15px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-image: url("/images/home/chevron.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 20px;
`;

const SearchPage: React.FC<RouteComponentProps> = () => {
  const defaultVars: searchProps = {
    keyword: "",
    yearFrom: "",
    yearTo: "",
    area: "",
    catalogueNo: "",
    category: "all",
  };

  const [searchVars, setSearchVars] = useState(defaultVars);

  const filterItems: filterProps[] = require("../data/filters.json");

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const filledVars = Object.keys(searchVars).filter(
      // @ts-ignore
      (key) => searchVars[key].length > 0
    );

    if (filledVars.length) {
      navigate("/search-results/" + searchVars.category, {
        state: { searchVars },
      });
    }
  };

  return (
    <CollectionWrap>
      <DefaultLayout title={{ top: "Explore the", base: "Archive" }}>
        <div style={{ padding: "54px", backgroundColor: "#F6F6F6" }}>
          <Form onSubmit={(e) => handleSubmit(e)} action="/search-results">
            <h1>Search Digital Gallery</h1>
            <p>
              View digitised versions of our newspapers, photographs, posters,
              books and more
            </p>

            <div
              style={{
                borderTop: "1px solid #d6d6d6",
                marginTop: 37,
                paddingTop: 44,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "start",
                gap: 15,
              }}
            >
              <TextInput
                type="text"
                placeholder="Keyword"
                onChange={(e) =>
                  setSearchVars({ ...searchVars, keyword: e.target.value })
                }
                style={{
                  width: "38.5%",
                  backgroundImage: "url(/images/home/search.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right 20px center",
                }}
              />

              <TextInput
                type="text"
                placeholder="Area"
                onChange={(e) =>
                  setSearchVars({ ...searchVars, area: e.target.value })
                }
                style={{ width: "28.3%" }}
              />

              <Label style={{ width: "4.5%" }}>Year</Label>

              <TextInput
                type="text"
                placeholder="From"
                onChange={(e) =>
                  setSearchVars({ ...searchVars, yearFrom: e.target.value })
                }
                style={{ width: "12.3%" }}
              />

              <TextInput
                type="text"
                placeholder="To"
                onChange={(e) =>
                  setSearchVars({ ...searchVars, yearTo: e.target.value })
                }
                style={{ width: "12.3%" }}
              />
            </div>

            <div
              style={{
                marginTop: 34,
                display: "flex",
                justifyContent: "start",
                gap: 15,
              }}
            >
              <TextInput
                type="text"
                placeholder="Library Catalogue ID"
                onChange={(e) =>
                  setSearchVars({ ...searchVars, catalogueNo: e.target.value })
                }
                style={{ width: "38.5%" }}
              />

              <Select
                style={{ width: "28.3%" }}
                onChange={(e) =>
                  setSearchVars({ ...searchVars, category: e.target.value })
                }
              >
                <option value="" disabled selected>
                  Search Category
                </option>
                {filterItems.map((item, k) => (
                  <option value={item.filter}>{item.title}</option>
                ))}
              </Select>

              <div style={{ display: "flex", marginLeft: "auto" }}>
                <PrimaryButton style={{ marginRight: 20 }}>
                  Search
                </PrimaryButton>

                <PrimaryButton onClick={() => navigate("/all-content")}>
                  Browse all Content
                </PrimaryButton>
              </div>
            </div>
          </Form>

          <h2>Featured Collections</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 45,
              paddingBottom: 45,
              borderBottom: "1px solid #d6d6d6",
            }}
          >
            <a
              onClick={() => {
                navigate("/search-results/enslavement", {
                  state: { searchVars },
                });
              }}
              style={{
                display: "flex",
                width: 658,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "33px 25px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/enslavement-thumb.jpg"
                alt="Enslavement"
                style={{ border: "1px solid #d6d6d6", marginRight: 25 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Enslavement</h3>
                  <p>
                    Browse Gateshead Archive
                    <br />
                    featured collections
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>

            <a
              onClick={() => {
                navigate("/search-results/womens-history", {
                  state: { searchVars },
                });
              }}
              style={{
                display: "flex",
                width: 658,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "33px 25px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/womens-history-thumb.jpg"
                alt="Women’s History"
                style={{ border: "1px solid #d6d6d6", marginRight: 25 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Women’s History</h3>
                  <p>
                    Browse Gateshead Archive
                    <br />
                    featured collections
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>
            <a
              onClick={() => {
                navigate("/search-results/gateshead-at-war", {
                  state: { searchVars },
                });
              }}
              style={{
                display: "flex",
                width: 658,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "33px 25px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/war-thumb.jpg"
                alt="Gateshead at War"
                style={{ border: "1px solid #d6d6d6", marginRight: 25 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Gateshead at War</h3>
                  <p>
                    Browse Gateshead Archive
                    <br />
                    featured collections
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>
            <a
              onClick={() => {
                navigate("/search-results/migration-to-gateshead", {
                  state: { searchVars },
                });
              }}
              style={{
                display: "flex",
                width: 658,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "33px 25px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/migration-thumb.jpg"
                alt="Migration to Gateshead"
                style={{ border: "1px solid #d6d6d6", marginRight: 25 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Migration to Gateshead</h3>
                  <p>
                    Browse Gateshead Archive
                    <br />
                    featured collections
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>
          </div>

          <h2>Search our Catalogues</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: 45,
              borderBottom: "1px solid #d6d6d6",
            }}
          >
            <a
              href="https://prism.librarymanagementcloud.co.uk/gateshead/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                width: 658,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "33px 25px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/libraries-thumb.jpg"
                alt="Books"
                style={{ border: "1px solid #d6d6d6", marginRight: 25 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Books</h3>
                  <p>
                    Search the Library Catalogue <br />
                    for Local History Books
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>

            <a
              href="http://www.gatesheadlocalstudies.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                width: 658,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "33px 25px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/collections-thumb.jpg"
                alt="Collections"
                style={{ border: "1px solid #d6d6d6", marginRight: 25 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Collections</h3>
                  <p>
                    Search the Archive Catalogue <br /> for all other
                    collections
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>
          </div>

          <h2>Online Resources</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: 45,
              borderBottom: "1px solid #d6d6d6",
            }}
          >
            <a
              href=" https://www.findmypast.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                width: 425,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "20px 24px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/find-my-past-logo.jpg"
                alt="Find My Pas Logo"
                style={{ border: "1px solid #d6d6d6", marginRight: 20 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>
                    Find My <br /> Past
                  </h3>
                </div>
                <p style={{ fontSize: 14 }}>
                  View your family <br /> history
                </p>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>

            <a
              href="https://www.borrowbox.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                width: 425,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "20px 24px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/borrowbox-logo.jpg"
                alt="NewsBank Logo"
                style={{ border: "1px solid #d6d6d6", marginRight: 20 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>Borrowbox</h3>
                  <p style={{ fontSize: 14 }}>
                    a world of <br /> information
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>

            <a
              href="https://www.britishnewspaperarchive.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                width: 425,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "20px 24px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/newspaper-archive-logo.jpg"
                alt="The British Newspaper Archive Logo"
                style={{ border: "1px solid #d6d6d6", marginRight: 20 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>
                    British <br /> Newspaper <br /> Archive
                  </h3>
                  <p style={{ fontSize: 14 }}>View papers</p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>

            <a
              href="http://gateshead.onlineculture.co.uk/ttp/ttp.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                width: 425,
                border: "1px solid #d6d6d6",
                borderBottomWidth: 7,
                backgroundColor: "white",
                padding: "20px 24px",
                margin: "0 15px",
              }}
            >
              <img
                src="/images/home/roll-of-honour-logo.jpg"
                alt="100 World War One Logo"
                style={{ border: "1px solid #d6d6d6", marginRight: 20 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={{ marginTop: 0 }}>
                    Roll of <br /> Honour
                  </h3>
                  <p style={{ fontSize: 14 }}>
                    Men who nobly <br /> fell
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: "#b78b1d" }}
                />
              </div>
            </a>
          </div>
        </div>
      </DefaultLayout>
    </CollectionWrap>
  );
};

export default SearchPage;
