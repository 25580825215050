import Box from "../Box"
import styled from "styled-components"

export interface IFlexProps {
  size?: number;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}

const getFlexSize = (size: number): number => 100 / size;

const Flex = styled(Box)<IFlexProps>`
  display: flex;
  flex-wrap: wrap;

  /**
   * Use the 'width' prop to set the number of columns
   * that should sit within the flex wrapper.
   */
  ${props =>
    props.size &&
    `
    & > ${Box} {
      width: ${getFlexSize(props.size)}%`};
    }
`;

export default Flex;
