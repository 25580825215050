import AttractorSlider from "./AttractorSlider"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Title, { IBaseTitleSettings, ITopTitleSettings } from "../Title"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GALogo, GCLogo, HeritageLogo } from "../Logo"

import {
  StyledRootAttractor,
  StyledDivider,
  StyledRootCard,
  StyledTitleWrap,
  StyledButton,
  StyledButtonWrap,
  StyledSpan,
  StyledRootFooter,
} from './styles';

interface IAttractorProps {
  topTitle: string;
  baseTitle: string;
  topTitleSettings?: ITopTitleSettings;
  baseTitleSettings?: IBaseTitleSettings;

  ctaText: string;
  handler: Function;
  images: Array<string>;
  path?: string;
}

const LogoWrap = styled.div`
  img {
    display: block;
    height: 117px;
    margin-bottom: 30px;
    width: 170px;
  }
`;

interface IStyledCardProps {
  shouldHide: boolean;
}

const StyledCard = styled(StyledRootCard)<IStyledCardProps>`
  position: relative;
  transition: all 300ms ease-out;

  ${props =>
    props.shouldHide &&
    `
    opacity: 0;
    z-index: -1;
  `}
`;

const MapsAttractor: React.FC<IAttractorProps> = ({
  topTitle,
  baseTitle,
  topTitleSettings,
  baseTitleSettings,
  ctaText,
  handler,
  images,
}) => {
  const [sliderActive, setSliderState] = useState<boolean>(false);
  const handleClick = () => setSliderState(false);

  useEffect(() => {
    const timer = setInterval(() => setSliderState(!sliderActive), 15000);
    return () => clearInterval(timer);
  }, [sliderActive]);

  return (
    <StyledRootAttractor onClick={() => handleClick()}>
      <AttractorSlider images={images} shouldPlay={sliderActive} />

      <StyledCard shouldHide={sliderActive}>
        <LogoWrap>
          <GALogo />
        </LogoWrap>

        <StyledTitleWrap>
          <Title
            topTitle={topTitle}
            baseTitle={baseTitle}
            topTitleSettings={topTitleSettings}
            baseTitleSettings={baseTitleSettings}
          />
        </StyledTitleWrap>

        <StyledButtonWrap>
          <StyledButton fontSize={24} fullWidth onClick={() => handler()}>
            <StyledSpan>{ctaText}</StyledSpan>
            <FontAwesomeIcon icon={faArrowRight} size="1x" />
          </StyledButton>
        </StyledButtonWrap>

        <StyledRootFooter>
          <div>
            <GCLogo />
          </div>

          <StyledDivider />

          <div>
            <HeritageLogo />
          </div>
        </StyledRootFooter>

        <span className="border-close" />
      </StyledCard>
    </StyledRootAttractor>
  );
};

export default MapsAttractor;
