import React from 'react';

export const GALogo: React.FC = () => (
  <img src={require('./ga-logo.svg')} alt="Gateshead Archives Logo" />
);

export const GCLogo: React.FC = () => (
  <img
    src={require('./gc-logo.svg')}
    alt="Gateshead Council Logo"
    style={{ width: 145 }}
  />
);

export const HeritageLogo: React.FC = () => (
  <img
    className="heritage-logo"
    src={require('./heritage-logo.svg')}
    alt="Lottery Heritage Logo"
    style={{ width: 140 }}
  />
);
