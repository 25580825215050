import React from "react"
import styled from "styled-components"
import { Box, Flex, GALogo, Title } from "angel-ui"
import { Container, Footer, Header } from "./common"
import { FooterLogo } from "."
import { navigate } from "@reach/router"
/**
 * Default Layout
 * This layout contains a header, footer and main container component.
 * Sometimes the header may not be required so we conditionally display
 * this with the use of a prop.
 */

const StyledHeader = styled(Header)`
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
`

const HeaderTextWrap = styled(Flex)`
  justify-content: center;
  width: 100%;

  h1 {
    margin-bottom: 0;
  }
`

interface ILayoutProps {
  title: ITitleProps
  backPath?: string
}

interface ITitleProps {
  top: string
  base: string
}

const DefaultLayout: React.FC<ILayoutProps> = ({ children, title, backPath = "/" }) => {
  return (
    <div>
      <StyledHeader>
        <Flex size={3} alignItems="center">
          <Box>
            <FooterLogo onClick={() => navigate("/")}>
              <GALogo />
            </FooterLogo>
          </Box>

          <Box>
            <HeaderTextWrap>
              <Title
                topTitle={title.top}
                baseTitle={title.base}
                baseTitleSettings={{
                  fontWeight: 500,
                  size: 80,
                }}
                topTitleSettings={{
                  size: 24,
                  top: -25,
                  right: -2,
                }}
              />
            </HeaderTextWrap>
          </Box>
          <Box />
        </Flex>
      </StyledHeader>
      <main>
        <Container>{children}</Container>
      </main>
      <Footer></Footer>
    </div>
  )
}

export default DefaultLayout
