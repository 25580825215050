export interface Colors {
  black: string,
  gold: string,
  white: string,
  blue: string,
  navy: string,
  grey: string,
  darkGrey: string,
  ash: string,
};

export interface Buttons {
  primary: object,
  secondary: object,
};

export interface Theme {
  buttons: Buttons,
  colors: Colors,
  fontSizes: Array<number>
  space: Array<number>
};

const colors = {
  black: '#000e1a',
  gold: '#b78b1d',
  white: '#fff',
  blue: '#007ce0',
  navy: '#004175',
  grey: '#d8d8d8',
  darkGrey: '#bcbcbc',
  ash: '#929292',
};

const createTheme = (): Theme => {
  return {
    colors,
    buttons: {
      primary: {
        background: colors.gold,
        color: colors.white,
        border: colors.white,
      },
      secondary: {
        background: colors.white,
        color: colors.black,
        border: colors.grey,
      }
    },
    fontSizes: [16, 18, 20, 24, 32],
    space: [0, 4, 8, 16, 32, 64, 72]
  }
};

export default createTheme;
