import React, { useEffect, useState } from "react"
import RecordPage from "./pages/record"
import SearchPage from "./pages/search"
import SearchResultsPage from "./pages/search-results"
import { createTheme, GlobalStyle, MapsAttractor, ThemeProvider } from "angel-ui"
import { navigate, RouteComponentProps, Router } from "@reach/router"
import {baseURL} from "./api/constants";

interface Image {
  filename: string
}

const SearchRoute = (props: RouteComponentProps) => <SearchPage {...props} />

const SearchResultRoute = (props: RouteComponentProps) => <SearchResultsPage {...props} />
const AllContentRoute = (props: RouteComponentProps) => <SearchResultsPage {...props} />
const RecordDetailRoute = (props: RouteComponentProps & { id?: string | undefined }) => (
  <RecordPage {...props} />
)

const App: React.FC = () => {
  const [images, setImages] = useState([])

  // Get the images
  useEffect(() => {
    async function getImages() {
      const res = await fetch(`${baseURL}/api/collections`)

      if (res.status === 200) {
        const data = await res.json()
        const images = data.attractorScreenImages.images.map((image: Image) => image.filename)

        setImages(images)
      }
    }

    getImages()
  }, [])

  return (
    <ThemeProvider theme={createTheme}>
      <GlobalStyle />

      <Router>
        <MapsAttractor
          images={images}
          path="/"
          handler={() => navigate("/search")}
          topTitle="Explore the"
          baseTitle="Archive"
          ctaText="Start Searching"
          topTitleSettings={{ size: 42, fontWeight: 300, right: 0, top: -50 }}
          baseTitleSettings={{ size: 120, fontWeight: 400 }}
        />

        <SearchRoute path="/search" />
        <SearchResultRoute path="/search-results/:filter/*page" />
        <AllContentRoute path="/all-content" />
        <RecordDetailRoute path="/record/:id" />
      </Router>
    </ThemeProvider>
  )
}

export default App
