import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Box from '../Box';

export interface ICardProps {
  padding?: string;
}

interface IMapDataProps {
  district: string;
  year: string;
  scale: string;
  collection: string;
}

interface IMapCardProps {
  image: string;
  data: IMapDataProps;
}

const Card = styled(Box)<ICardProps>`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  padding: ${props =>
    props.padding ? props.padding : `${props.theme.space[4]}px`};
`;

const StyledMapCard = styled(Card)`
  background: none;
  box-shadow: none;
  padding: 0;
  flex-direction: column;
  padding: 16px;
`;

const MapCardImageWrap = styled.div`
  border: 1px solid ${props => props.theme.colors.ash};
  height: 300px;
  position: relative;

  img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const MapCardBody = styled.div`
  padding: ${props => props.theme.space[1]}px 0;

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-weight: 300;
      margin-bottom: ${props => props.theme.space[1]}px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    li.collection {
      max-width: 400px;
    }
  }
`;

const MapCardButton = styled.div`
  background-color: #f6f6f6;
  border-top: 1px solid ${props => props.theme.colors.ash};
  border-left: 1px solid ${props => props.theme.colors.ash};
  bottom: -1px;
  font-size: 8px;
  height: 37px;
  padding: 11px;
  position: absolute;
  right: -1px;
  text-align: center;
  width: 48px;
  z-index: 10;

  svg {
    color: ${props => props.theme.colors.gold};
  }
}
`;

const LabelText = styled.span`
  font-weight: 500;
`;

export const MapCard: React.FC<IMapCardProps> = ({ image, data }) => {
  return (
    <StyledMapCard>
      <MapCardImageWrap>
        <img src={image} alt="Name of Image" />

        <MapCardButton>
          <FontAwesomeIcon icon={faChevronRight} size="2x" />
        </MapCardButton>
      </MapCardImageWrap>

      <MapCardBody>
        <ul>
          <li>
            <LabelText>
              {data.district}, {data.year}
            </LabelText>
          </li>
          <li>
            <LabelText>Scale:</LabelText> {data.scale}
          </li>
          <li className="collection">
            <LabelText>Collection:</LabelText> {data.collection}
          </li>
        </ul>
      </MapCardBody>
    </StyledMapCard>
  );
};

export default Card;
