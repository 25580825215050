import DefaultLayout from "./Default"
import styled from "styled-components"

export const FooterLogo = styled.div`
  img {
    width: 160px;
    height: 115px;
  }
`

export const SpinnerWrap = styled.div`
  height: 485px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export { DefaultLayout }
