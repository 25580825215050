import React from "react"
import styled from "styled-components"

export interface ITopTitleSettings {
  top?: number;
  right?: number;
  size?: number;
  fontWeight?: number;
}

export interface IBaseTitleSettings {
  size?: number;
  fontWeight?: number;
}

interface ITopTitleProps {
  topTitleSettings?: ITopTitleSettings;
}

interface IBaseTitleProps {
  baseTitleSettings?: IBaseTitleSettings;
}

interface ITitleProps {
  topTitle: string;
  baseTitle: string;
  topTitleSettings?: ITopTitleSettings;
  baseTitleSettings?: IBaseTitleSettings;
}

const BaseTitle = styled.h1`
  display: block;
  text-align: center;
  padding: 0;
  line-height: 0.5;
  color: #424242;
`;

const TopTitle = styled.span<ITopTitleProps>`
  display: block;
  font-size: ${props =>
    props.topTitleSettings ? `${props.topTitleSettings.size}px` : `52px`};
  font-weight: ${props =>
    props.topTitleSettings ? `${props.topTitleSettings.fontWeight}` : `300`};
  position: relative;
  right: ${props =>
    props.topTitleSettings ? `${props.topTitleSettings.right}px` : `-80px`};
  top: ${props =>
    props.topTitleSettings ? `${props.topTitleSettings.top}px` : `-25px`};
`;

const MainTitle = styled.span<IBaseTitleProps>`
  display: block;
  font-weight: ${props =>
    props.baseTitleSettings ? `${props.baseTitleSettings.fontWeight}` : `400`};
  font-size: ${props =>
    props.baseTitleSettings ? `${props.baseTitleSettings.size}px` : `215px`};
`;

const Title: React.FC<ITitleProps> = ({
  topTitle,
  baseTitle,
  topTitleSettings,
  baseTitleSettings,
}) => {
  return (
    <BaseTitle>
      <TopTitle topTitleSettings={topTitleSettings}>{topTitle}</TopTitle>
      <MainTitle baseTitleSettings={baseTitleSettings}>{baseTitle}</MainTitle>
    </BaseTitle>
  );
};

export default Title;
