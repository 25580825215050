import styled from 'styled-components';

export interface IButtonProps {
  color?: string;
  fontSize?: number;
  fullWidth?: boolean;
  disabled?: boolean;
}

const common = {
  display: `inline-block`,
  'text-align': `center`,
  'text-decoration': `none`,
};

export const PrimaryButton = styled.button<IButtonProps>`
  ${common}
  background: ${props =>
    props.color ? props.color : props.theme.buttons.primary.background};
  border: 1px solid ${props => props.theme.buttons.primary.border};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.buttons.primary.color};
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.fontSizes[1]}px;
  font-weight: 300;
  padding: ${props => props.theme.space[3]}px ${props =>
  props.theme.space[4]}px;

  ${props =>
    props.fullWidth &&
    `
      display: block;
      width: 100%;
    `}

  ${props => props.disabled && `opacity: .5;`}
`;

export const SecondaryButton = styled.button<IButtonProps>`
  ${common}
  background: ${props =>
    props.color ? props.color : props.theme.buttons.secondary.background};
  border: 1px solid ${props => props.theme.buttons.secondary.border};
  color: ${props => props.theme.buttons.secondary.color};
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.fontSizes[1]}px;
  font-weight: 300;
  padding: ${props => props.theme.space[3]}px ${props =>
  props.theme.space[4]}px;

  ${props =>
    props.fullWidth &&
    `
      display: block;
      width: 100%;
    `}

  ${props => props.disabled && `opacity: .5;`}
`;

export default PrimaryButton;
