import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import AttractorImage from './Image';

const StyledSlider = styled(Slider)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .slick-list,
  .slick-track {
    height: 100vh;
  }
`;

const settings = {
  dots: false,
  arrows: false,
  fade: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface IAttractorSliderProps {
  images: Array<string>;
  shouldPlay?: boolean;
}

const AttractorSlider: React.FC<IAttractorSliderProps> = ({
  images,
  shouldPlay,
}) => {
  const slider: any = useRef();
  const props = { ...settings, shouldPlay };

  // Use Effect to test for update in prop
  useEffect(() => {
    if (shouldPlay === null) {
      return;
    }

    if (shouldPlay) {
      slider.current.slickPlay();
    } else {
      slider.current.slickPause();
    }
  }, [shouldPlay]);

  return (
    <StyledSlider {...props} ref={slider}>
      {images.map((image, k) => {
        return (
          <div key={k}>
            <AttractorImage src={image} />
          </div>
        );
      })}
    </StyledSlider>
  );
};

export default AttractorSlider;
