import styled from "styled-components"

// Header { This will be abstracted }
export const Header = styled.header`
  padding: ${props => props.theme.space[5]}px ${props => props.theme.space[4]}px
    60px;
  text-align: center;
`

// Footer { This will be abstracted }
export const Footer = styled.footer`
  padding: 28px ${props => props.theme.space[4]}px;
`

interface IPropsContainer {
  noPaddingBottom?: boolean
}

// Container { This will be abstracted }
export const Container = styled.div<IPropsContainer>`
  background-color: white;
  border-bottom: 1px solid #cdcdcd;
  border-top: 1px solid #cdcdcd;
  padding: 0;
  ${props => props.noPaddingBottom && `padding-bottom: 0;`}
`
