import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { DefaultLayout } from "../layouts";
import {
  filterProps,
  recordProps,
  searchProps,
  searchLinksProps,
  searchMetadataProps,
} from "../data/types";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { NextButton, PrevButton } from "../components/nav-button";
import { PrimaryButton } from "angel-ui";
import { useState } from "react";
import { SpinnerWrap } from "../layouts";
import Spinner from "react-spinkit";
import {baseURL} from "../api/constants";

const CollectionWrap = styled.div`
  *:focus {
    outline: none;
  }

  h1 {
    margin: 40px;
    padding: 0;
    font-size: 45px;
    font-weight: 500;
    color: #424242;
  }

  h2 {
    margin: 40px 0;
    padding: 0;
    font-size: 40px;
    font-weight: 500;
    color: #424242;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 0;
    font-weight: 500;
    color: #424242;
  }

  p {
    font-size: 14px;
    margin-top: 0;
    font-weight: 300;
    color: #424242;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    text-decoration: none;
    color: inherit;
  }
`;

const BreadCrumbWrap = styled.ul`
  list-style-type: none;
  display: flex;
  border-bottom: 1px solid #dadada;
  padding: 20px 40px;
  margin: 0;
`;
const BreadCrumb = styled.li`
  border-left: 1px solid black;
  padding-left: 9px;
  margin-right: 9px;
  font-weight: 300;
  color: #424242;
  &:first-of-type {
    border: none;
    padding-left: 0;
  }
`;

const TabWrap = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #dadada;
  padding: 0 40px 0 40px;
`;

interface TabItemProps {
  active?: boolean;
}

const TabItem = styled.li<TabItemProps>`
  display: flex;
  padding: 0 0 14px 0;
  text-align: center;
  font-size: 17px;
  align-items: flex-end;
  border-bottom: 5px solid transparent;
  cursor: pointer;
  transition: border-color 150ms linear;
  font-weight: 300;
  color: #424242;
  &:hover {
    border-color: #b78b1d;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #b78b1d;
    `}
`;
const TabSeperator = styled.div`
  width: 1px;
  background-color: #e2e2e2;
  border-top: 10px solid white;
  border-bottom: 10px solid white;
`;

const SearchHeaderWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin: 0;
`;
const PagerWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding: 20px 40px;
  margin: 0;
`;

const Pager = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchResults = styled.ul`
  list-style-type: none;
  margin: 40px;
  padding: 0;
  max-width: 1240px;
`;

const SearchResult = styled.li`
  display: block;
  padding: 0 0 40px 0;
  margin: 0 0 40px 0;
  border-bottom: 1px solid #eaeaea;
  font-size: 14px;
  align-items: top;
  clear: left;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
const RecordMetaDataListWrap = styled.ul`
  margin: 0 0 25px 0;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
`;
const RecordMetaDataListItem = styled.li`
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  font-weight: 300;
  color: #424242;
`;
interface StateProps {
  activeFilter: string;
  searchResults: recordProps[];
  searchLinks: searchLinksProps;
  searchMeta: searchMetadataProps;
  searchVars: searchProps;
  searchResultsTitle: string;
}

interface PageProps extends RouteComponentProps {
  page?: number;
  filter?: string;
}

const SearchResultsPage: React.FC<PageProps> = (props) => {
  const navState = props.location?.state as StateProps;
  const currentPage = props?.page ?? 1;
  const activeFilter = props?.filter ?? "all";

  const [searchResults, setSearchResults] = useState<recordProps[]>(
    navState.searchResults ?? []
  );
  const [searchMeta, setSearchMeta] = useState<searchMetadataProps | null>(
    navState.searchMeta ?? null
  );
  const [searchLinks, setSearchLinks] = useState<searchLinksProps | null>(
    navState.searchLinks ?? null
  );
  //const [activeFilter, setActiveFilter] = useState(navState.activeFilter ?? "")
  const [loading, setLoading] = useState(true);

  const searchVars: searchProps = navState.searchVars ?? "";
  const filterItems: filterProps[] = require("../data/filters.json");

  let searchResultsTitle = searchVars.keyword ?? "All content";

  if (!searchResultsTitle.length) {
    searchResultsTitle = "All records";
  }

  if (searchVars.area?.length) {
    searchResultsTitle += ` in ${searchVars.area}`;
  }

  if (searchVars?.yearFrom) {
    searchResultsTitle += ` from ${searchVars.yearFrom}`;
  }

  if (searchVars?.yearTo) {
    searchResultsTitle += ` to ${searchVars.yearTo}`;
  }

  // Get the images
  useEffect(() => {
    async function getSearchResults() {
      setLoading(true);

      const url = `${baseURL}/api/research-stations/search?page=${currentPage}`;

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          ...searchVars,
          category: activeFilter === "all" ? "" : activeFilter,
        }),
      });

      if (res.status === 200) {
        const result = await res.json();
        setSearchResults(result.data);
        setSearchMeta(result.meta);
        setSearchLinks(result.links);
      }
      setLoading(false);
    }

    getSearchResults();
  }, [searchResults.length, searchVars, currentPage, activeFilter]);

  return (
    <CollectionWrap>
      <DefaultLayout title={{ top: "Explore the", base: "Archive" }}>
        <BreadCrumbWrap>
          <BreadCrumb
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/search")}
          >
            Home
          </BreadCrumb>
          <BreadCrumb>Search Results : {searchResultsTitle}</BreadCrumb>
        </BreadCrumbWrap>
        <SearchHeaderWrap>
          <h1 style={{ margin: 0, fontSize: 35 }}>
            Search Results : {searchResultsTitle}
          </h1>
          <PrimaryButton onClick={() => navigate("/search")}>
            Search Again
          </PrimaryButton>
        </SearchHeaderWrap>

        <TabWrap>
          {filterItems
            .filter((i) => i.row == 1)
            .map((item, k) => (
              <React.Fragment key={k}>
                <TabItem
                  active={item.filter === activeFilter}
                  onClick={() =>
                    navigate(`/search-results/${item.filter}/1`, {
                      state: {
                        searchResults,
                        searchMeta,
                        searchLinks,
                        searchVars,
                        searchResultsTitle,
                      },
                      replace: true,
                    })
                  }
                >
                  <img
                    src={item.image}
                    style={{ marginRight: 10 }}
                    alt={item.title}
                  />
                  {item.title}
                </TabItem>

                {k < filterItems.filter((i) => i.row == 1).length - 1 && (
                  <TabSeperator />
                )}
              </React.Fragment>
            ))}
        </TabWrap>
        <TabWrap
          style={{
            justifyContent: "start",
            gap: 20,
            paddingLeft: 187,
            alignItems: "stretch",
          }}
        >
          {filterItems
            .filter((i) => i.row == 2)
            .map((item, k) => (
              <React.Fragment key={k}>
                <TabItem
                  style={{ marginTop: 10 }}
                  active={item.filter === activeFilter}
                  onClick={() =>
                    navigate(`/search-results/${item.filter}/1`, {
                      state: {
                        searchResults,
                        searchMeta,
                        searchLinks,
                        searchVars,
                        searchResultsTitle,
                      },
                      replace: true,
                    })
                  }
                >
                  <img
                    src={item.image}
                    style={{ marginRight: 10 }}
                    alt={item.title}
                  />
                  {item.title}
                </TabItem>

                {k < filterItems.filter((i) => i.row == 2).length - 1 && (
                  <TabSeperator />
                )}
              </React.Fragment>
            ))}
        </TabWrap>

        <PagerWrap>
          {searchResults.length > 0 && (
            <p>
              {searchResults.length === 1
                ? "1 result"
                : `${searchMeta?.from} - ${searchMeta?.to} of ${searchMeta?.total} results`}
            </p>
          )}

          {searchMeta?.current_page && (
            <Pager>
              {searchMeta.current_page > 1 && (
                <Link
                  to={`/search-results/${activeFilter}/${searchMeta.current_page -
                    1}`}
                  state={{
                    searchResults,
                    searchMeta,
                    searchLinks,
                    searchVars,
                    searchResultsTitle,
                  }}
                >
                  <PrevButton>Previous</PrevButton>
                </Link>
              )}

              {searchMeta.current_page < searchMeta.last_page && (
                <Link
                  style={{ marginLeft: 20 }}
                  to={`/search-results/${activeFilter}/${searchMeta.current_page +
                    1}`}
                  state={{
                    searchResults,
                    searchMeta,
                    searchLinks,
                    searchVars,
                    searchResultsTitle,
                  }}
                >
                  <NextButton>Next</NextButton>
                </Link>
              )}
            </Pager>
          )}
        </PagerWrap>
        {loading ? (
          <SpinnerWrap>
            <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
          </SpinnerWrap>
        ) : searchResults.length > 0 ? (
          <SearchResults>
            {searchResults.map((row) => (
              <SearchResult key={row.id}>
                <div
                  style={{
                    display: "inline",
                    float: "left",
                    width: 329,
                    height: 505,
                  }}
                >
                  <img
                    src={row.thumbnail_image_url}
                    alt={row.title}
                    style={{
                      objectFit: "contain",
                      objectPosition: "0 0",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <div style={{ height: 505, marginLeft: 364 }}>
                  <h2 style={{ fontSize: 28, marginTop: 0, marginBottom: 5 }}>
                    {row.title}
                  </h2>
                  {/* <h2 style={{ fontSize: 28, marginTop: 0 }}></h2> */}
                  <p
                    style={{
                      fontSize: 14,
                      marginBottom: 30,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      src={
                        filterItems.find(
                          (i) => i.filter === row.categories[0].slug
                        )?.image
                      }
                      alt=""
                    />
                    {row.categories[0].title}
                  </p>
                  <p style={{ fontSize: 14, marginBottom: 30, lineHeight: 2 }}>
                    {row.short_desc || row.body_text.substr(0, 400)}
                  </p>
                  <RecordMetaDataListWrap>
                    {row.library_id && (
                      <RecordMetaDataListItem>
                        <strong>ID number : </strong> {row.library_id}
                      </RecordMetaDataListItem>
                    )}

                    {row.year && (
                      <RecordMetaDataListItem>
                        <strong>Year : </strong> {row.year}
                      </RecordMetaDataListItem>
                    )}

                    {row.access_status && (
                      <RecordMetaDataListItem>
                        <strong>Access Conditions : </strong>{" "}
                        {row.access_status}
                      </RecordMetaDataListItem>
                    )}

                    {row.format.length > 0 && (
                      <RecordMetaDataListItem>
                        <strong>Content Type : </strong> {row.format.join(", ")}
                      </RecordMetaDataListItem>
                    )}

                    {row.class_no && (
                      <RecordMetaDataListItem>
                        <strong>Class Number : </strong> {row.class_no}
                      </RecordMetaDataListItem>
                    )}
                  </RecordMetaDataListWrap>

                  <Link
                    to={`/record/${row.id}`}
                    title={row.title}
                    state={{
                      activeFilter,
                      searchResults,
                      searchMeta,
                      searchLinks,
                      searchVars,
                      searchResultsTitle,
                    }}
                  >
                    <PrimaryButton
                      style={{
                        fontSize: 14,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      Read More
                    </PrimaryButton>
                  </Link>
                </div>
              </SearchResult>
            ))}
          </SearchResults>
        ) : (
          <p
            style={{
              padding: "50px 0",
              margin: 0,
              fontSize: 35,
              textAlign: "center",
            }}
          >
            No Results
          </p>
        )}

        <PagerWrap>
          {searchResults.length > 0 && (
            <p>
              {searchResults.length === 1
                ? "1 result"
                : `${searchMeta?.from} - ${searchMeta?.to} of ${searchMeta?.total} results`}
            </p>
          )}

          {searchMeta?.current_page && (
            <Pager>
              {searchMeta.current_page > 1 && (
                <Link
                  to={`/search-results/${activeFilter}/${searchMeta.current_page -
                    1}`}
                  state={{
                    searchResults,
                    searchMeta,
                    searchLinks,
                    searchVars,
                    searchResultsTitle,
                  }}
                >
                  <PrevButton>Previous</PrevButton>
                </Link>
              )}

              {searchMeta.current_page < searchMeta.last_page && (
                <Link
                  style={{ marginLeft: 20 }}
                  to={`/search-results/${activeFilter}/${searchMeta.current_page +
                    1}`}
                  state={{
                    searchResults,
                    searchMeta,
                    searchLinks,
                    searchVars,
                    searchResultsTitle,
                  }}
                >
                  <NextButton>Next</NextButton>
                </Link>
              )}
            </Pager>
          )}
        </PagerWrap>
      </DefaultLayout>
    </CollectionWrap>
  );
};

export default SearchResultsPage;
