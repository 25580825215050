import styled from 'styled-components';

import Button from '../Button';
import Box from '../Box';
import Card from '../Card';

export const StyledRootAttractor = styled(Box)`
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;

  .heritage-logo {
    top: -6px;
    position: relative;
  }
`;

export const StyledRootCard = styled(Card)`
  height: 700px;
  width: 750px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: ${props => props.theme.space[5]}px ${props => props.theme.space[6]}px;
  position: relative;
  z-index: 10;

  &::before {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: -30px;
    left: -30px;
    border-top: 2px solid ${props => props.theme.colors.white};
    border-left: 2px solid ${props => props.theme.colors.white};
  }

  &::after {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: -30px;
    right: -30px;
    border-top: 2px solid ${props => props.theme.colors.white};
    border-right: 2px solid ${props => props.theme.colors.white};
  }

  .border-close {
    &::before {
      display: block;
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: -30px;
      left: -30px;
      border-bottom: 2px solid ${props => props.theme.colors.white};
      border-left: 2px solid ${props => props.theme.colors.white};
    }

    &::after {
      display: block;
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: -30px;
      right: -30px;
      border-bottom: 2px solid ${props => props.theme.colors.white};
      border-right: 2px solid ${props => props.theme.colors.white};
    }
  }
`;

export const StyledRootFooter = styled(Box)`
  width: 100%;
  justify-content: space-between;
`;

export const StyledTitleWrap = styled.div`
  text-align: center;
  width: 100%;
`;

export const StyledDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${props => props.theme.colors.grey};
`;

export const StyledButtonWrap = styled.div`
  margin: 80px 0 40px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  svg {
    color: ${props => props.theme.colors.white};
  }
`;

export const StyledSpan = styled.span`
  display: block;
  text-align: center;
  width: 100%;
`;

export const StyledFancySpan = styled.p`
  line-height: 0.5;
  text-align: center;
  font-size: 26px;
  font-weight: 100;
  span {
    display: inline-block;
    position: relative;
  }

  span:before,
  span:after {
    content: '';
    position: absolute;
    height: 9px;
    border-bottom: 1px solid ${props => props.theme.colors.grey};
    top: 0;
    width: 600px;
  }

  span:before {
    right: 100%;
    margin-right: 15px;
  }

  span:after {
    left: 100%;
    margin-left: 15px;
  }
`;