import React from "react";
import styled from "styled-components";
import { DefaultLayout } from "../layouts";
import {
  faFileAlt,
  faFilePdf,
  faPrint,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  filterProps,
  recordProps,
  searchProps,
  searchMetadataProps,
  searchLinksProps,
} from "../data/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate, RouteComponentProps } from "@reach/router";
import { PrimaryButton } from "angel-ui";
import { useState } from "react";

const CollectionWrap = styled.div`
  *:focus {
    outline: none;
  }

  h1 {
    margin: 40px;
    padding: 0;
    font-size: 45px;
    font-weight: 500;
    color: #424242;
  }

  h2 {
    margin: 40px 0;
    padding: 0;
    font-size: 40px;
    font-weight: 500;
    color: #424242;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 0;
    font-weight: 500;
    color: #424242;
  }

  p {
    font-size: 14px;
    margin-top: 0;
    font-weight: 300;
    color: #424242;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    text-decoration: none;
    color: inherit;
  }
`;

const BreadCrumbWrap = styled.ul`
  list-style-type: none;
  display: flex;
  border-bottom: 1px solid #dadada;
  padding: 20px 40px;
  margin: 0;
`;
const BreadCrumb = styled.li`
  border-left: 1px solid black;
  padding-left: 9px;
  margin-right: 9px;
  font-weight: 300;
  color: #424242;
  &:first-of-type {
    border: none;
    padding-left: 0;
  }
`;
const SearchHeaderWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin: 0 0 35px;
  border-bottom: 1px solid #dedede;
`;
const RecordMetaDataListWrap = styled.ul`
  margin: 0 0 25px 0;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
`;
const RecordMetaDataListItem = styled.li`
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  font-weight: 300;
  color: #424242;
`;

interface StateProps {
  activeFilter: string;
  searchResults: recordProps[];
  searchVars: searchProps;
  searchResultsTitle: string;
  searchMeta: searchMetadataProps;
  searchLinks: searchLinksProps;
}

const RecordPage: React.FC<RouteComponentProps & {
  id?: string | undefined;
}> = (props) => {
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [showTransript, setShowTransript] = useState(false);

  const filterItems: filterProps[] = require("../data/filters.json");

  if (!props.location?.state) {
    return <p>404 - No Results</p>;
  }

  const {
    searchResults,
    searchMeta,
    searchLinks,
    searchVars,
    searchResultsTitle,
  } = props.location?.state as StateProps;

  if (!searchResults) {
    return <p>404 - No Results</p>;
  }

  const record = searchResults.find((i) => i.id === Number(props.id));

  if (!record) {
    return <p>404 - No Record</p>;
  }

  if (record.master_file.cloud_url && showFullScreenImage) {
    return (
      <img
        src={record.master_file.cloud_url}
        alt={`${record.title}`}
        style={{ marginRight: 30 }}
        onClick={() => setShowFullScreenImage(!showFullScreenImage)}
      />
    );
  }

  const articleImage =
    record.article_image_url ?? record.thumbnail_image_url ?? null;

  return (
    <CollectionWrap>
      <DefaultLayout title={{ top: "Explore the", base: "Archive" }}>
        <BreadCrumbWrap>
          <BreadCrumb
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/search")}
          >
            Home
          </BreadCrumb>
          <BreadCrumb
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/search-results/all/${searchMeta.current_page}`, {
                state: {
                  searchResults,
                  searchMeta,
                  searchLinks,
                  searchVars,
                  searchResultsTitle,
                },
              })
            }
          >
            Search Results : {searchResultsTitle}
          </BreadCrumb>
          <BreadCrumb
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/search-results/${record.categories[0].slug}}/${searchMeta.current_page}`,
                {
                  state: {
                    searchResults,
                    searchMeta,
                    searchLinks,
                    searchVars,
                    searchResultsTitle,
                  },
                }
              )
            }
          >
            {record.categories[0].title}
          </BreadCrumb>
          <BreadCrumb>{record.title}</BreadCrumb>
        </BreadCrumbWrap>

        <SearchHeaderWrap>
          <h1 style={{ margin: 0, fontSize: 35 }}>{record.title}</h1>
          <PrimaryButton onClick={() => navigate("/search")}>
            Search Again
          </PrimaryButton>
        </SearchHeaderWrap>

        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "stretch",
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 35,
          }}
        >
          {record.master_file.file_type === "Image" && (
            <img
              src={articleImage}
              alt={record.title}
              style={{ marginRight: 30, width: 890, flexGrow: 1 }}
              onClick={() => setShowFullScreenImage(!showFullScreenImage)}
            />
          )}

          {record.master_file.file_type === "PDF" && articleImage && (
            <img
              src={articleImage}
              alt={record.title}
              style={{ marginRight: 30, width: 890, flexGrow: 1 }}
            />
          )}

          {record.master_file.file_type === "Video" && (
            <div style={{ height: 485, marginRight: 30, flexGrow: 1 }}>
              <video
                src={record.master_file.cloud_url}
                height={485}
                controls={true}
                poster={articleImage}
              />
            </div>
          )}

          {record.master_file.file_type === "Audio" && (
            <div
              style={{
                width: 890,
                height: 485,
                marginRight: 30,
                position: "relative",
                maxWidth: "50%",
              }}
            >
              <img
                src={articleImage}
                style={{
                  objectFit: "cover",
                  objectPosition: "50% 50%",
                  width: "100%",
                  height: "100%",
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 475,
                }}
              >
                <audio src={record.master_file.cloud_url} controls={true} />
              </div>
            </div>
          )}

          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {showTransript && record.transcript_text ? (
              <div style={{ maxHeight: 391, overflowY: "scroll" }}>
                {record.transcript_text.split("\n").map((item, key) => {
                  return (
                    <p
                      key={key}
                      style={{
                        fontSize: 14,
                        marginTop: 0,
                        marginBottom: 35,
                        lineHeight: 1.5,
                      }}
                    >
                      {item}
                    </p>
                  );
                })}{" "}
              </div>
            ) : (
              <div>
                {record.body_text.split("\n").map((item, key) => {
                  return (
                    <p
                      key={key}
                      style={{
                        fontSize: 14,
                        marginTop: 0,
                        marginBottom: 35,
                        lineHeight: 1.5,
                      }}
                    >
                      {item}
                    </p>
                  );
                })}
                <RecordMetaDataListWrap style={{ marginTop: 5, flex: 1 }}>
                  {record.library_id && (
                    <RecordMetaDataListItem>
                      <strong>ID number : </strong> {record.library_id}
                    </RecordMetaDataListItem>
                  )}
                  {record.year && (
                    <RecordMetaDataListItem>
                      <strong>Year : </strong> {record.year}
                    </RecordMetaDataListItem>
                  )}
                  {record.access_status && (
                    <RecordMetaDataListItem>
                      <strong>Access Conditions : </strong>{" "}
                      {record.access_status}
                    </RecordMetaDataListItem>
                  )}
                  {record.format.length > 0 && (
                    <RecordMetaDataListItem>
                      <strong>Content Type : </strong>{" "}
                      {record.format.join(", ")}
                    </RecordMetaDataListItem>
                  )}
                  {record.class_no && (
                    <RecordMetaDataListItem>
                      <strong>Class Number : </strong> {record.class_no}
                    </RecordMetaDataListItem>
                  )}
                </RecordMetaDataListWrap>
              </div>
            )}

            <div style={{ paddingTop: 35, borderTop: "1px solid #DEDEDE" }}>
              <PrimaryButton
                onClick={() => navigate(-1)}
                style={{
                  fontSize: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginRight: 10,
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} /> Back
              </PrimaryButton>

              {record.master_file.file_type === "PDF" && (
                <PrimaryButton
                  style={{
                    fontSize: 14,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginRight: 10,
                  }}
                  onClick={() =>
                    window.open(record.master_file.cloud_url, "_blank")
                  }
                >
                  <FontAwesomeIcon icon={faFilePdf} /> View PDF
                </PrimaryButton>
              )}

              {record.transcript_text && record.transcript_text.length > 0 && (
                <PrimaryButton
                  style={{
                    fontSize: 14,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginRight: 10,
                  }}
                  onClick={() => setShowTransript(!showTransript)}
                >
                  <FontAwesomeIcon icon={faFileAlt} /> Transcript
                </PrimaryButton>
              )}

              <PrimaryButton
                onClick={() => window.print()}
                style={{
                  fontSize: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </PrimaryButton>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </CollectionWrap>
  );
};

export default RecordPage;
